const requestCaptcha = (function() {
    const event = new CustomEvent('updated-request-store');

    const captchaSuccess = response => {
        window.requestStore.data.captcha = response;
        window.requestStore.status.captcha = Boolean(response);
        document.dispatchEvent(event);
    };

    const captchaExpired = () => {
        window.requestStore.status.captcha = false;
        document.dispatchEvent(event);
    };

    const init = () => {
        window.captchaSuccess = captchaSuccess;
        window.captchaError = console.error;
        window.captchaExpired = captchaExpired;
    };

    return {
        init,
    };
})();
