;(function ($) {
    var Calendar = function ($calendar) {
        const EVENT_URL = '/events/{slug}';

        this.methods = {
            init: function ($calendar) {
                $calendar.fullCalendar({
					header: {
						left: 'prevYear, prev',
						center: 'title',
						right: 'next, nextYear'
					},
					lang: ($calendar.data('language') ? $calendar.data('language') : 'uk'),
					dragScroll: false,
					navLinks: true,
					editable: true,
					eventLimit: true,
					timeFormat: 'h:mm',
                    events: '/api/events',
                    eventClick: function (calEvent, jsEvent, view) {
                        document.location.href = EVENT_URL.replace('{slug}', calEvent.slug);
                    }
                });
            }
        };

        this.methods.init($calendar);

        return this.methods;
    };

    $('[data-calendar]').each(function () {
        new Calendar($(this));
    })
})(window.jQuery);