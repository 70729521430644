const requestEDS = (function() {
    const elemLock = getElementByClass('js-eds-lock');
    const elemForm = getElementByClass('js-request-form');

    /**
     * @type {{locked: string, loading: string, done: string}}
     */
    const status = {
        locked: 'locked',
        loading: 'loading',
        done: 'done',
    };

    const showLock = currentStatus => {
        elemLock.classList.remove(...Object.values(status));
        elemLock.classList.add(currentStatus);
    };

    const callbackAfterAuth = async () => {
        showLock(status.loading);
        const formData = new FormData(elemForm);
        const formDataObject = Object.fromEntries(formData.entries());
        const dataForSign = JSON.stringify(formDataObject);
        const sign = await window.eds.sign(dataForSign, {
            asBase64String: true,
        });

        if (!sign) {
            window.requestStore.status.eds = false;
            alert('Виникла помилка при накладанні підпису');
            return;
        }

        const event = new CustomEvent('updated-request-store');

        window.requestStore.data.eds = new Blob([sign], {type: 'application/octet-stream'});
        window.requestStore.status.eds = true;

        showLock(status.done);
        document.dispatchEvent(event);
    };

    const options = {
        debug: false,
        callbackAfterAuth: callbackAfterAuth,
    };

    const init = async () => {
        await window.eds.init(options);
        await window.eds.loadWidget();
    };

    return {
        init,
        status,
    };
})();
