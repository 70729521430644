(function(){
    var _init = async function() {
        await window.eds.init();
        window.signToDoc.init(window.eds);
    };

    const handler = (event) => {
        event.preventDefault();
        const { target: { dataset: { url, title, date } } } = event;
        openPdf(url, title, date);
    };

    const openPdf = async (url, title, date) => {
        await _init();

        await window.signToDoc.setConfig({ url, type: window.signToDoc.TYPES.CONCLUSION });
        await window.signToDoc.open({ title, date });
    };

    document.addEventListener("DOMContentLoaded", async () => {
        const links = document.querySelectorAll("a[data-monitoring-conclusion]");
        links.forEach(link => link.addEventListener('click', handler));
    });
})();
