/**
 * Skeleton
 * <div class="toggle-text">
 *     <input type="checkbox" class="toggle-text__input" id="switcher">
 *
 *     <div class="toggle-text__content">
 *         CONTENT
 *     </div>
 *
 *     <label for="switcher" class="toggle-text__label"></label>
 * </div>
 */

(function() {
    'use strict';

    var blockList = document.querySelectorAll('.toggle-text');
    var selectorPath = {
        label: '.toggle-text__label',
        checkbox: '.toggle-text__input',
        content: '.toggle-text__content',
    };

    blockList.forEach(function(block, index) {
        var content = block.querySelector(selectorPath.content);

        if (content && content.innerHTML.length) {
            var checkbox = block.querySelector(selectorPath.checkbox);
            var label = block.querySelector(selectorPath.label);

            checkbox.id = 'switcher_' + index;
            label.setAttribute('for', 'switcher_' + index);
        } else {
            block.style.display = 'none';
        }
    });
})();
