(function(){
    'use strict';

    var tablesList = document.querySelectorAll('.toggle');
    var countAlwaysVisibleRow = 4;

    for(var i = 0; i < tablesList.length; i++) {
        var minHeight = 0;
        var tableRows = tablesList[i].querySelectorAll('.toggle__table .table__row');
        var checkbox = tablesList[i].querySelector('.toggle__input');
        var label = tablesList[i].querySelector('.toggle__label');

        if (label[i]) {
            label[i].setAttribute('for', 'switch_' + i);
        }

        if(tableRows.length <= countAlwaysVisibleRow) {
            label.style.display = 'none';
        } else {
            checkbox.id = 'switch_' + i;
            label.setAttribute('for', 'switch_' + i);
        };

        for(var j = 0; j < countAlwaysVisibleRow; j++) {
            if(tableRows[j]) minHeight += tableRows[j].offsetHeight;
        };

        tablesList[i].querySelector('.toggle__content').style.maxHeight = minHeight+'px';
    };
})();
