/**
 * @type {requestStore}
 */
window.requestStore = {
    status: {
        eds: false,
        captcha: false,
    },

    data: {
        eds: null,
        captcha: null,
    },

    isActiveStatuses() {
        return Object.values(this.status).every(Boolean);
    },
};
