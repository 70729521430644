(function () {
    var _init = async function () {
        await window.eds.init();
        window.signToDoc.init(window.eds);
    };

    const handler = (event) => {
        event.preventDefault();
        const {target: {dataset: {url, title, date, link}}} = event;
        openPdf(url, title, date, link);
    };

    const openPdf = async (url, title, date, link) => {
        await _init();

        await window.signToDoc.setConfig({url, type: window.signToDoc.TYPES.NAZK});
        await window.signToDoc.open({title, date, link});
    };

    document.addEventListener("DOMContentLoaded", async () => {
        const links = document.querySelectorAll("a[data-napc-register]");
        links.forEach(link => link.addEventListener('click', handler));
    });
})();
