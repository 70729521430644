const requestPopup = (function() {
    const REGEXP = {
        EMAIL: /^[^@]+@\w+(\.\w+)+\w$/,
        ROW_HAS_NUMBER: /\d/,
        ROW_ONLY_NUMBER: /^\d+$/,
    };
    const NUMBERS = {
        POST_CODE_MIN_LENGTH: 5,
        POST_CODE_MAX_LENGTH: 5,
        MIN_TEXT_LENGTH: 3,
        MIN_DESCRIPTION_LENGTH: 5,
    };

    const classRequest  = 'js-request';
    const classContent  = 'js-request-content';
    const classForm     = 'js-request-form';
    const classOpen     = 'js-request-trigger';
    const classClose    = 'js-request-close';
    const classSend     = 'js-request-send';
    const classReturn   = 'js-request-return';
    const classLoader   = 'js-request-loader';
    const classTitle    = 'js-request-title';
    const classSubtitle = 'js-request-subtitle';
    const classShadow   = 'js-shadow';
    const classActive   = 'active';
    const classHidden   = 'hidden';
    const classSuccess  = 'success';
    const classError    = 'error';
    const classAnimIn   = 'animation-in';
    const classAnimOut  = 'animation-out';

    const elemRequest    = getElementByClass(classRequest);
    const elemContent    = getElementByClass(classContent);
    const elemForm       = getElementByClass(classForm);
    const elemOpen       = getElementByClass(classOpen);
    const elemClose      = getElementByClass(classClose);
    const elemSend       = getElementByClass(classSend);
    const elemReturn     = getElementByClass(classReturn);
    const elemLoader     = getElementByClass(classLoader);
    const elemTitle      = getElementByClass(classTitle);
    const elemSubtitle   = getElementByClass(classSubtitle);
    const elemShadow     = getElementByClass(classShadow);
    const elemEdsLock    = getElementByClass('js-eds-lock');
    const elemInputsLock = getElementByClass('js-request-main-inputs-lock');
    const elemsInput     = document.querySelectorAll(`
        .${classForm} input[name],
        .${classForm} textarea[name],
        .${classForm} select[name]
    `);

    const countFields = 9;

    const elemsToggle = [
        elemRequest,
        elemShadow,
    ];

    const classesOpen = [
        classActive,
        classAnimIn,
    ];

    const classesClose = [
        classActive,
        classAnimIn,
        classAnimOut,
    ];

    const inputValidationConfig = {
        'description': (value) => value.length >= NUMBERS.MIN_DESCRIPTION_LENGTH,
        'parties[0][name]': (value) => value.length >= NUMBERS.MIN_TEXT_LENGTH,
        'parties[0][contactPoint][email]': (value) => REGEXP.EMAIL.test(value),
        'parties[0][address][countryName]': (value) => value.length >= NUMBERS.MIN_TEXT_LENGTH,
        'parties[0][address][locality]': (value) => value.length >= NUMBERS.MIN_TEXT_LENGTH,
        'parties[0][address][streetAddress]': (value) => value.length >= NUMBERS.MIN_TEXT_LENGTH
            && REGEXP.ROW_HAS_NUMBER.test(value),
        'parties[0][address][postalCode]': (value) => REGEXP.ROW_ONLY_NUMBER.test(value)
            && value.length >= NUMBERS.POST_CODE_MIN_LENGTH && value.length <= NUMBERS.POST_CODE_MAX_LENGTH,
    };

    const isValidField = (name, value) => inputValidationConfig[name] ? inputValidationConfig[name](value) : true;

    const getFormElement = (name) => document.querySelector(`.${classForm} [name="${name}"]`);

    const openPopup = () => elemsToggle.forEach(elem => elem.classList.add(...classesOpen));

    const closePopup = () => {
        elemsToggle.forEach(elem => elem.classList.add(classAnimOut));

        elemRequest.onanimationend = () => {
            elemsToggle.forEach(elem => elem.classList.remove(...classesClose));
            elemRequest.onanimationend = null;
        };
    };

    /**
     * @param {boolean=} disabled
     */
    const toggleSendBtnState = disabled => {
        if (disabled !== undefined) {
            elemSend.disabled = disabled;
            return;
        }

        elemSend.disabled = !validateForm() || !window.requestStore.isActiveStatuses();
    };

    const toggleEDSLock = () => {
        if (elemEdsLock.classList.contains(requestEDS.status.done)) {
            return;
        }

        const method = validateForm() ? 'remove' : 'add';
        elemEdsLock.classList[method](requestEDS.status.locked);
    };

    const disableFormInputs = () => {
        if (window.requestStore.status.eds === false) {
            return;
        }

        elemInputsLock.classList.add(classActive);
    };

    /**
     * @returns {boolean}
     */
    const validateForm = () => {
        const formData = new FormData(elemForm);
        const fieldKeys = new Set();

        const excludedKeys = [
            'tenderId',
            'g-recaptcha-response',
        ];

        let countValidFields = 0;

        for (const [key, value] of formData.entries()) {
            if (fieldKeys.has(key) || excludedKeys.includes(key) || !isValidField(key, value)) {
                continue;
            }

            fieldKeys.add(key);
            countValidFields += Number(Boolean(value));
        }

        return countValidFields === countFields;
    };

    const validateInput = event => {
        const {name, value} = event.target;

        if (isValidField(name, value)) {
            getFormElement(name).classList.remove(classError);
        } else {
            getFormElement(name).classList.add(classError);
        }
    };

    const sendForm = () => {
        const xhr = new XMLHttpRequest();
        const formData = new FormData(elemForm);

        formData.append('eds', window.requestStore.data.eds);

        elemLoader.classList.remove(classHidden);

        xhr.open('POST', '/requests', true);

        xhr.onreadystatechange = function() {
            if (xhr.readyState !== XMLHttpRequest.DONE) {
                return;
            }

            elemLoader.classList.add(classHidden);
            elemContent.classList.add(classHidden);
            elemSend.classList.add(classHidden);
            elemSubtitle.classList.remove(classHidden);
            elemReturn.classList.remove(classHidden);

            switch (xhr.status) {
                case 201:
                    elemTitle.classList.add(classSuccess);
                    break;
                case 403:
                    elemSubtitle.innerHTML = 'Виникла помилка при валідації капчі';
                    elemTitle.classList.add(classError);
                    break;
                default:
                    elemSubtitle.innerHTML = 'Виникла помилка';
                    elemTitle.classList.add(classError);
            }
        };

        xhr.send(formData);
    };

    const init = () => {
        elemOpen.addEventListener('click', openPopup);
        elemClose.addEventListener('click', closePopup);
        elemShadow.addEventListener('click', closePopup);
        elemReturn.addEventListener('click', closePopup);
        elemSend.addEventListener('click', () => {
            toggleSendBtnState(true);
            sendForm();
        });
        elemsInput.forEach(elem => elem.addEventListener('change', event => {
            validateInput(event);
            toggleEDSLock();
            toggleSendBtnState();
        }));
        document.addEventListener('updated-request-store', () => {
            disableFormInputs();
            toggleSendBtnState();
        });
    };

    return {
        init,
    };
})();
