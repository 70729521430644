(function () {
    var _init = async function () {
        await window.eds.init();
        window.signToDoc.init(window.eds);
    };

    const handler = (event) => {
        event.preventDefault();
        const {target: {dataset: {url, title, date, link, type}}} = event;
        openPdf(url, title, date, link, type);
    };

    const openPdf = async (url, title, date, link, type) => {
        await _init();

        await window.signToDoc.setConfig({url, type});
        await window.signToDoc.open({title, date, link});
    };

    document.addEventListener("DOMContentLoaded", async () => {
        const links = document.querySelectorAll("a[data-tender-sign-to-doc]");
        links.forEach(link => link.addEventListener('click', handler));
    });
})();
/*
* <a
                href="#"
                data-monitoring-conclusion // data-tender-announcement
                data-url="{{ config('api.audit') . '/monitorings/' . $monitoring->id }}"  // link for tender object
                data-title="{{ $conclusionSign->title }}" // sign title sign.p7s
                data-date="{{ $conclusionSign->dateModified }}" // sign date modified
            >PDF</a>
* */