
if ($.browser.mobile) $('body').addClass('mobile');
if ($.browser.safari) $('body').addClass('safari');
if ($.browser.iphone || $.browser.ipad || $.browser.ipod ) $('body').addClass('ios');

$(document).ready( function () {
    $(this).keydown(function(eventObject){
        if (eventObject.which == 27){
            modal.animate({opacity: 0}, 200,
                function(){
                    $(this).css('display', 'none');
                    overlay.fadeOut(400);
                    $(".message_modal").removeClass('show');
                    $('body').removeClass('no-scroll');


                }
            );
            //$('#video_iframe')[0].contentWindow.postMessage('{"event":"command","func":"' + 'stopVideo' + '","args":""}', '*');
            $("iframe").each(function() {
                var src= $(this).attr('src');
                $(this).attr('src',src);
                $(this).setVolume(0);
            });
        }
    });

    $(".header .icon-menu").on('click', function () {
        $(this).parent().toggleClass('open');
        //$('body').toggleClass('no-scroll');
    });

    $(".block_faq_answer .item .faq-question").on('click', function () {
        $(this).next('.faq-answer').slideToggle();
    });
    $(".block_faq_answer .item .up_button").on('click', function () {
        $(this).closest('.faq-answer').slideUp();
    });

    $(".block_text_more .link_more_show").on('click', function () {
        $(this).addClass('hide').next().removeClass('hide').closest('.block_text_more').find('.item.hide').slideDown();
    });
    $(".block_text_more .link_more_show").on('click', function () {
        $(this).closest('.block_text_more').find('.item.hide').slideDown();
        $(this).hide().next().show();
    });
    $(".block_text_more .link_more_hide").on('click', function () {
        $(this).closest('.block_text_more').find('.item.hide').slideUp();
        $(this).hide();
        $('.link_more_show').show();
    });

    $(function() {

        var clickFunction = function(hash) {
            var hrefVal, target;
            if (typeof hash === 'string') {
                hrefVal = hash;
            } else {
                hrefVal = $(this).attr('href');
            }
            target = $(hrefVal);
            if (target.length) {

                $('html, body').animate({
                    scrollTop: target.offset().top
                }, 1000);

                return false;
            }
        };
        $('[href*="#"]:not([href="#"])').click(clickFunction);
        if (window.location.hash) {
            clickFunction(window.location.hash);
        }
    });
});
