/*!
 *
 * Prozorro v1.0.0
 *
 * Author: Lanko Andrey (lanko@perevorot.com)
 *
 * © 2015
 *
 */

var APP,
    INPUT,
    SEARCH_BUTTON,
    BLOCKS,
    INITED=false,
    LANG,
    SEARCH_TYPE,
    SEARCH_QUERY=[],
    SEARCH_QUERY_TIMEOUT,
    REGION=[],

    IS_MAC = /Mac/.test(navigator.userAgent),
    IS_HISTORY = (window.History ? window.History.enabled : false),

    KEY_BACKSPACE = 8,
    KEY_UP = 38,
    KEY_DOWN = 40,
    KEY_ESC = 27,
    KEY_RETURN = 13,
    KEY_CMD = IS_MAC ? 91 : 17,

    spin_options={
        color:'#6dc8eb',
        lines: 15,
        width: 2
    },

    spin_options_light={
        color:'#fff',
        lines: 15,
        width: 2
    };

(function(window, undefined){

    'use strict';

    var suggest_opened,
        suggest_current;

    function getCookie(name) {
        var matches = document.cookie.match(new RegExp(
            "(?:^|; )" + name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + "=([^;]*)"
        ));
        return matches ? decodeURIComponent(matches[1]) : undefined;
    }

    function setCookie(name, value, options) {
        options = options || {};

        var expires = options.expires;

        if (typeof expires == "number" && expires) {
            var d = new Date();
            d.setTime(d.getTime() + expires * 1000);
            expires = options.expires = d;
        }
        if (expires && expires.toUTCString) {
            options.expires = expires.toUTCString();
        }

        value = encodeURIComponent(value);

        var updatedCookie = name + "=" + value;

        for (var propName in options) {
            updatedCookie += "; " + propName;
            var propValue = options[propName];
            if (propValue !== true) {
                updatedCookie += "=" + propValue;
            }
        }
        document.cookie = updatedCookie;
    }

    function deleteCookie(name) {
        setCookie(name, "", {
            expires: -1
        })
    }

    APP = (function(){
        $.getJSON('/sources/ua/region.json', function (json) {
            REGION.push(json);
        });

        var viewport = function () {
            var e = window, a = 'inner';
            if (!('innerWidth' in window )) {
                a = 'client';
                e = document.documentElement || document.body;
            }
            return {width: e[a + 'Width'], height: e[a + 'Height']};
        };

        var decodeHTMLEntities = function(str) {
            if(str && typeof str === 'string') {
                str = str.replace(/</g,"&lt;");
                str = str.replace(/>/g,"&gt;");
                str = str.replace(/"/g, "&quot;");
            }

            return str;
        };

        /**
         * @param {Date} date
         * @return {string}
         */
        var dateToString = function(date) {
            var dateStr =
                ('0' + date.getDate()).slice(-2) + '.' +
                ('0' + (date.getMonth() + 1)).slice(-2) + '.' +
                date.getFullYear();

            dateStr += ' ' +
                ('0' + date.getHours()).slice(-2) + ':' +
                ('0' + date.getMinutes()).slice(-2) + ':' +
                ('0' + date.getSeconds()).slice(-2);

            return dateStr;
        };

        var methods =  {
            common: function(){
                $('html').removeClass('no-js');

                $('a.registration').bind('click', function(event){
                    event.preventDefault();
                    $('.startpopup').css('display', 'block');
                });

                $('.close-startpopup').bind('click', function(event){
                    event.preventDefault();
                    $('.startpopup').css('display', 'none');
                });

                $('a.qualification-criteria-link').click(function(e) {
                    e.preventDefault();
                    let $overlay = $(this).closest('.overlay-parent').children('.overlay');
                    $overlay.find('.qualification.documents').show();
                    $overlay.addClass('open');
                });

                $('a.document-link').click(function(e){
                    e.preventDefault();
                    const dataModalId = $(this).data('modal-id');
                    $(this).closest('.container').find('.tender--offers.documents').hide();
                    $(this).closest('.container').find('.tender--offers.documents[data-id='+$(this).data('id')+']').show();

                    if (dataModalId) {
                        $(this).closest('.container').find(`.overlay-documents[data-id="${dataModalId}"]`).addClass('open');
                    } else {
                        $(this).closest('.container').find('.overlay-documents:not([data-id])').addClass('open');
                    }
                });

                $('a.check-sign').click(function(e) {
                    e.preventDefault();

                    $(this).next('.overlay').addClass('open');
                });

                $('.overlay-close').click(function(e){
                    e.preventDefault();

                    $('.overlay').removeClass('open');
                });

                $(document).keydown(function(e){
                    if($('.overlay').is('.open')){
                        switch (e.keyCode){
                            case KEY_ESC:
                                $('.overlay-close').click();
                                return;
                                break;
                        }
                    }
                });
            },
            js: {
                banner: function (_self) {
                    var bannerUrl = $('[data-banner-url]').data('banner-url');
                    var all = $('.modal_close, #overlay, .link_more');

                    all.click( function () {
                        var finish = $('[data-time-finished]').data('time-finished');
                        setCookie('banner_closed', bannerUrl, {path: '/', expires: new Date(finish)});
                    });

                    if (getCookie('banner_closed') !== undefined && getCookie('banner_closed') !== bannerUrl) {
                        deleteCookie('banner_closed');
                    }

                    if(getCookie('banner_closed') === undefined && $(modal).hasClass('show')){
                        var modal_show = $('.modal_div.show');
                        overlay.fadeIn(100,
                            function(){
                                $(modal_show)
                                    .css('display', 'block')
                                    .animate({opacity: 1}, 100);
                                $('body').addClass('no-scroll');
                        });
                    }
                },
                video: function(_self){
                    var overlay = $('#overlay');
                    var close = $('.modal_close, #overlay');
                    var open_modal = $('.open_modal');
                    var modal = $('.modal_div');

                    open_modal.click( function(event){
                        event.preventDefault();
                        var div = $(this).attr('href');

                        overlay.fadeIn(100,
                            function(){
                                $(div)
                                    .css('display', 'block')
                                    .animate({opacity: 1, top: '50%'}, 100);
                                $('body').addClass('no-scroll');
                        });
                    });

                    close.click( function(){
                        modal
                            .animate({opacity: 0, top: '45%'}, 100,
                                function(){
                                    $(this).css('display', 'none');
                                    overlay.fadeOut(100);
                                    $(".message_modal").removeClass("show");
                                    $('body').removeClass('no-scroll');
                        });

                        $("iframe").each(function() {
                            var src= $(this).attr('src');

                            $(this).attr('src', src);
                        });


                    });
                },

                links: function (_self) {
                    $('[data-url]').each(function() {
                        $(this).attr('href', $(this).data('url'));
                    });
                },

                events_map: function(_self){
                },
                customer_search: function () {
                    $('#tender-customer').selectize({
                        valueField: 'key',
                        labelField: 'value',
                        searchField: 'value',
                        create: false,
                        render: {
                            option: function(item, escape) {
                                return '<div>' + escape(item.value) + '</div>';
                            }
                        },
                        load: function(query, callback) {
                            $.ajax({
                                url: '/customers/search?query=' + encodeURIComponent(query),
                                type: 'GET',
                                error: function() {
                                    callback();
                                },
                                success: function(res) {
                                    var arrayOfEdrpou = [];

                                    for (var item in res) {
                                        arrayOfEdrpou.push({
                                            key: item,
                                            value: res[item]
                                        });
                                    }
                                    callback(arrayOfEdrpou.slice(1, 20));
                                }
                            });
                        }
                    });
                },
                disableSearchButton: function (_self) {
                    $('#c-find-form').validate({
                        messages: {
                            tid: 'Формат: UA-2016-01-01-000001'
                        },
                        errorPlacement: function(error, element) {
                            error.appendTo('#errordiv');
                        },
                        rules: {
                            tid: {
                                required: false,
                                pattern: /^UA\-20\d{2}\-\d{2}\-\d{2}\-\d{6}(\-([a-z]))?$/
                            }
                        }
                    });

                    jQuery.extend(jQuery.validator.messages, {
                        // Add custom text message for pattern
                        pattern: "Invalid pattern format for tender number.",
                    });

                    $('input[id="btn-find"]').prop('disabled', true);
                    $('input[id="tender-number"], input[id="tender-customer"]').keyup(function () {
                        if ($(this).val() != '') {
                            $('input[id="btn-find"]').prop('disabled', false);
                        } else {
                            $('input[id="btn-find"]').prop('disabled', true);
                        }
                    });
                },
                imageSlider: function(_self){
                    _self.slick({
                        dots: true,
                        autoplay: _self.data('autoplay')
                    });
                },
                feedback_thanks: function(_self){
                    var send_more=_self.find('.send-more'),
                        close=_self.find('.close'),
                        opened=_self.is(':visible');

                    send_more.click(function(e){
                        e.preventDefault();
                        _self.fadeOut();

                        opened=false;
                        $('.form-button').click();
                    });

                    close.click(function(e){
                        e.preventDefault();

                        $('.form-button').fadeIn();
                        opened=false;
                        _self.fadeOut();
                    });

                    if(!$('.center-page-form').length){
                        $(document).on('keydown', function(e) {
                            if(opened){
                                switch (e.keyCode){
                                    case KEY_ESC:
                                        close.click();
                                        return;
                                        break;
                                }
                            }
                        }).click(function(e){
                            if(opened && !$(e.target).closest('.form-container').length){
                                close.click();
                            }
                        });
                    }
                },
                feedback: function(_self){
                    var button=_self.next(),
                        form=_self.find('form'),
                        close=_self.find('.close'),
                        footer=$('.navbar.footer'),
                        opened=false;

                    _self.find('[name="phone"]').inputmask({
                        "mask": "+99 (999) 999-99-99"
                    });

                    close.click(function(e){
                        e.preventDefault();

                        opened=false;
                        _self.fadeOut();
                        button.fadeIn();
                    });

                    button.click(function(e){
                        e.preventDefault();

                        opened=true;
                        form.show();
                        _self.fadeIn();
                        button.fadeOut();
                        scrollForm();
                    });

                    var toggleButton=function() {
                        if ($(this).scrollTop()+viewport().height > parseInt(footer.offset().top)-50) {
                            button.fadeOut('fast');
                        } else {
                            button.fadeIn('fast');
                        }
                    }

                    var scrollForm=function(){
                        _self[form.outerHeight()>viewport().height ? 'addClass':'removeClass']('form-scrolled');

                    }

                    $(window).resize(scrollForm);

                    //toggleButton();

                    //$(window).scroll(toggleButton);

                    if(!$('.center-page-form').length){
                        $(document).on('keydown', function(e) {
                            if(opened){
                                switch (e.keyCode){
                                    case KEY_ESC:
                                        close.click();
                                        return;
                                        break;
                                }
                            }
                        }).click(function(e){
                            if(opened && !$(e.target).closest('.form-container').length){
                                close.click();
                            }
                        });
                    }
                },
                lot_tabs: function(_self){
                    var tabs_content=$('.'+_self.data('tab-class')),
                        tabs=_self.find('a');

                    tabs.click(function(e){
                        e.preventDefault();

                        tabs_content.removeClass('active');
                        tabs_content.eq($(this).parent().index()).addClass('active');
                    });
                },
                monitorings_tabs: function(_self){
                    var tabs_content=$('.'+_self.data('monitoring-tab-class')),
                        tabs=_self.find('a');

                    tabs.click(function(e){
                        e.preventDefault();

                        tabs_content.removeClass('active');
                        tabs_content.eq($(this).parent().index()).addClass('active');
                    });
                },
                liabilities_tabs: function(_self){
                    var tabs_content=$('.'+_self.data('liability-tab-class')),
                        tabs=_self.find('a');

                    tabs.click(function(e){
                        e.preventDefault();

                        tabs_content.removeClass('active');
                        tabs_content.eq($(this).parent().index()).addClass('active');
                    });
                },
                liability_docs_tabs: function(_self){
                    var tabs_content=$('.'+_self.data('liability-docs-tab-class')),
                        tabs=_self.find('a');

                    tabs.click(function(e){
                        e.preventDefault();

                        tabs_content.removeClass('active');
                        tabs_content.eq($(this).parent().index()).addClass('active');
                    });
                },
                appeal_docs_tabs: function(_self){
                    var tabs_content=$('.'+_self.data('appeal-docs-tab-class')),
                        tabs=_self.find('a');

                    tabs.click(function(e){
                        e.preventDefault();

                        tabs_content.removeClass('active');
                        tabs_content.eq($(this).parent().index()).addClass('active');
                    });
                },
                openpopup: function(_self) {
                    _self.click(function(e){
                        if(!$(e.target).is('a')){
                            e.preventDefault();

                            _self.fadeOut(function(){
                                _self.remove();
                            });
                        }
                    });
                },
                go_up_down: function() {
                    var offset = 220,
                        duration = 500,
                        goto_up=$('.back-to-top'),
                        goto_down=$('.go-down');

                    $(window).scroll(function() {
                        if ($(this).scrollTop() > offset) {
                            goto_up.fadeIn(duration);
                        } else {
                            goto_up.fadeOut(duration);
                        }
                    });

                    goto_up.click(function(event) {
                        event.preventDefault();

                        $('html, body').animate({
                            scrollTop: 0
                        }, duration);
                    });

                    var topset = $(document).height()- 2*($(window).height());

                    $(window).scroll(function() {
                        if ($(this).scrollTop() < (topset+200)) {
                            goto_down.fadeIn(duration);
                        } else {
                            goto_down.fadeOut(duration);
                        }
                    });

                    var do_action = false;

                    goto_down.click(function(event) {
                        if(do_action){
                            return;
                        }

                        do_action=true;

                        event.preventDefault();

                        $('html, body').animate({
                            scrollTop: ($(document).scrollTop() + $(window).height())
                        }, duration, function(){
                            do_action=false;
                        });
                    });
                },
                home_more: function(_self){
                    var text_height=0,
                        text=$('.description .text'),
                        check_height=function(){
                            text_height=$('.text-itself').height()+20;
                        },
                        opened=false;

                    $(window).resize(check_height);

                    check_height();

                    _self.click(function(e){
                        e.preventDefault();
                        $(this).closest('.description').toggleClass('opened');

                        text.animate({
                            height: !opened ? text_height : 0
                        }, 400);

                        opened=!opened;
                    });

                    $('.slider-list').slick({
                        dots: true,
                        arrows: true,
                        speed: 300,
                        slidesToShow: 1,
                        pauseOnDotsHover: true,
                        pauseOnHover: true,
                        autoplay: true,
                        infinite: false
                    });
                },
                home_equal_height: function(_self){
                    var max_height=0,
                        blocks=_self.find('[block]');

                    blocks.each(function(i){
                        max_height=Math.max($(this).height(), max_height);
                    });

                    blocks.height(max_height);
                },

                tender_sign_check: function(_self, selfData) {
                    var cssValid = 'color: green;';
                    var loader = _self.find('.sign-loader');
                    var isMonitoring = selfData.monitoring !== undefined;

                    if (selfData.url === undefined && selfData.sign === undefined) {
                        alert('Відсутні дані для перевірки');
                        return;
                    }

                    _self.find('a').click(async function(e) {
                        var signPlaceholder = _self.find('.signPlaceholder');

                        if (signPlaceholder.length === 0) {
                            signPlaceholder = $('#signPlaceholder');
                        }

                        e.preventDefault();
                        loader.spin(spin_options);
                        signPlaceholder.html('');

                        var _pad = num => String(num).padStart(2, '0');

                        var _createSignerTime = time => {
                            return [
                                _pad(time.day), '.', _pad(time.month), '.', time.year,
                                ' ',
                                _pad(time.hour), ':', _pad(time.minute), ':', _pad(time.second),
                            ].join('');
                        };

                        var _createSignerFields = signer => {
                            var keyAliasMap = {
                                subjectFullName: () => 'Підписувач',
                                issuerCN: () => 'ЦСК',
                                subjectOrg: () => 'Назва органу',
                                time: isTimeStamp => isTimeStamp ? 'Мітка часу' : 'Час підпису',
                            };

                            return Object.entries(keyAliasMap).map(([key, keyAlias]) => {
                                var value = signer[key];

                                if (keyAlias === undefined || value === '') {
                                    return false;
                                }

                                if (key === 'time') {
                                    if (signer.isTimeAvail) {
                                        value = _createSignerTime(signer[key]);
                                    } else {
                                        value = 'Час підпису відсутній';
                                    }
                                }

                                return keyAlias(signer.isTimeAvail) + ': <b>' + value + '</b><br/>';
                            }).filter(Boolean).join('');
                        };

                        var _showSigners = function(signers) {
                            return signers.reverse().map((signer) => {
                                return _createSignerFields(signer) + '<br />';
                            }).join('');
                        }

                        var _showDifference = signInfo => {
                            var customDiffPatch = jsondiffpatch.create({
                                arrays: {
                                    detectMove: true,
                                },
                            });
                            var customDelta = customDiffPatch.diff(signInfo.data.fromSign, signInfo.data.fromDb);
                            var htmlDiff = jsondiffpatch.formatters.html.format(customDelta, signInfo.data.fromDb);

                            var diffWrapper = document.createElement('details');
                            var diffTitle = document.createElement('summary');
                            var diffContent = document.createElement('div');

                            diffWrapper.classList.add('details');
                            diffContent.classList.add('content');

                            jsondiffpatch.formatters.html.hideUnchanged();
                            diffContent.innerHTML = htmlDiff;
                            diffTitle.append('Відмінності з попередньою редакцією');
                            diffTitle.style.color = 'indianred';
                            diffTitle.style.fontWeight = 'bold';
                            diffWrapper.append(diffTitle);
                            diffWrapper.append(diffContent);
                            signPlaceholder.append(diffWrapper);
                            signPlaceholder.append("<br>");
                        }

                        var _showStatusOk = () => {
                            signPlaceholder.append("<b style='" + cssValid + "'>Підпис вірний</b><br><br>");
                        };

                        /**
                         * @param {VerifyObjectResponse[] | undefined} response
                         * @return {void}
                         */
                        var _callbackCheckSign = function (signInfo) {
                            loader.spin(false);
                            loader.hide();

                            if (signInfo === undefined) {
                                signPlaceholder.html('Підпис відсутній');
                                return;
                            }

                            if (!isMonitoring) {
                                if (signInfo.difference !== undefined) {
                                    _showDifference(signInfo);
                                } else {
                                    _showStatusOk();
                                }
                            }

                            var certInfo = _showSigners(signInfo.signers) || '';
                            signPlaceholder.append(certInfo);
                        };

                        var _init = async function(data, methodName) {
                            await window.eds.init();
                            var result = await window.eds[methodName](data);
                            var isArray = Array.isArray(result);
                            _callbackCheckSign(isArray ? result[0] : result);
                        };

                        try {
                            await _init([selfData.url], 'verifyObjects');
                        } catch (e) {
                            var resp = await fetch(selfData.sign);
                            var data = await resp.arrayBuffer();
                            var sign = btoa(String.fromCharCode(...new Uint8Array(data)));

                            await _init(sign, 'verify');
                        }
                    });
                },

                edt_download: function(_self) {
                    var _init = async function() {
                        await window.eds.init();
                        window.signToDoc.init(window.eds);
                    };

                    _self.find('a').click(async function() {
                        await _init();

                        await window.signToDoc.setConfig({url: _self.data('url'), type: window.signToDoc.TYPES.TICKET});
                        await window.signToDoc.open({title: _self.data('title')});
                    });
                },

                tender_menu_fixed: function(_self){
                    return;
                    var offset_element=$('.wide-table:first'),
                        offset=0;

                    if(offset_element.length)
                        offset=offset_element.offset().top-50;

                    _self.sticky({
                        topSpacing: _self.position().top-80,
                        responsiveWidth: true,
                        bottomSpacing: 4000
                    });
                },
                tender: function(_self){
                    var url_antivirus = _self.data('url_antivirus');

                    if(url_antivirus) {
                        $('.modal_div').on('click', '.close_virus_modal', function(e){
                            e.preventDefault();
                            var modal = $('.modal_div');
                            var overlay = $('#overlay');

                            modal.animate({opacity: 0, top: '45%'}, 100, function(){
                                $(this).css('display', 'none');
                                overlay.fadeOut(100);
                                $(".message_modal").removeClass("show");
                                $('body').removeClass('no-scroll');
                            });
                        });

                        _self.on('click', 'a[data-storage]', function(e){
                            e.preventDefault();

                            var self = $(this);
                            var document_id = self.data('storage');
                            var date = self.data('date');
                            var href = self.attr('href');
                            var overlay = $('#overlay');
                            var close = $('.modal_close, #overlay');
                            var modal = $('.modal_div');

                            $.ajax({
                                url: url_antivirus+'/document/'+document_id+'?date='+date,
                                method: 'GET',
                                dataType: "json",
                                success: function (obj) {
                                    if(obj.infected === false) {
                                        window.location = href;
                                        return;
                                    }

                                    modal.show().animate({
                                        opacity: 100,
                                        top: '45%'
                                    });

                                    overlay.fadeIn(100, function(){
                                        $('#document .modal-content').html(
                                            '<div class="info_dozorro">' +
                                            '<p><h3>Увага!</h3></p><br>' +
                                            '<p>Можливо файл <strong>'+self.html()+'</strong> заражено вірусом <strong>'+obj.reason+'</strong>.<br><br>Завантаження файлу може бути небезпечним для вашого комп’ютера.</p>' +
                                            '<p><br></p>'+
                                            '<p style="text-align:center;margin-top:50px"><p style="margin-left:150px;"><a class="button" style="width:200px;float:left" href="'+ href +'">Завантажити</a> <a class="button close_virus_modal" style="margin-left:20px;width:150px;float:left;background-color:#888" href="">Скасувати</a></p></p>' +
                                            '</div>'
                                        );

                                        $('body').addClass('no-scroll');
                                    });
                                },
                                error: function(){
                                    window.location = href;
                                }
                            });
                        });
                    }

                    _self.on('click', '.question--open', function(e){
                        e.preventDefault();
                        var self=$(this);

                        self.closest('.questions-block').find('.none').toggle();
                        self.toggleClass('open');

                        $('html, body').animate({
                            scrollTop: self.closest('.row.questions').offset().top-50
                        }, 500);
                    });

                    _self.on('click', '.search-form--open', function(e){
                        e.preventDefault();

                        $(this).closest('.description-wr').toggleClass('open');
                    });

                    _self.find('.blue-btn').click(function(e){
                        e.preventDefault();

                        $('html, body').animate({
                            scrollTop: $('.tender--platforms').position().top
                        }, 500);
                    });

                    _self.find('.tender--offers--ancor').click(function(e){
                        e.preventDefault();

                        $('html, body').animate({
                            scrollTop: $('.tender--offers.margin-bottom-xl').position().top-30
                        }, 500);
                    });

                    $('a.documents-all').click(function(e){
                        e.preventDefault();

                        $('.overlay-documents-all').addClass('open');
                    });

                    $('a.info-all').click(function(e){
                        e.preventDefault();

                        $('.overlay-info-all').addClass('open');
                    });
                },
                search_result: function(_self){
                    _self.on('click', '.search-form--open', function(e){
                        e.preventDefault();
                        $(this).closest('.description-wr').toggleClass('open');
                    });

                    _self.on('click', '.show-more', function(e){
                        e.preventDefault();

                        $('.show-more').addClass('loading').spin(spin_options_light);

                        $.ajax({
                            url: LANG+'/'+SEARCH_TYPE+'/form/search',
                            data: {
                                query: APP.utils.get_query(),
                                start: $('.show-more').data('start')
                            },
                            method: 'post',
                            headers: APP.utils.csrf(),
                            dataType: "json",
                            success: function(response){
                                $('.show-more').remove();

                                if(response.html){
                                    $('#result').append(response.html);

                                    APP.utils.result_highlight(response.highlight);
                                }
                            }
                        });
                    });
                },
                form: function(_self){

                    var timeout,
                        input_query='',
                        $document=$(document);

                    APP.utils.totals.init();

                    LANG=_self.data('lang').slice(0, -1);
                    SEARCH_TYPE=_self.data('type');

                    if(['', '/en', '/ru'].indexOf(LANG)===-1){
                        return;
                    }

                    INPUT=_self;
                    BLOCKS=$('#blocks');

                    SEARCH_BUTTON=$('#search_button');

                    setInterval(function(){
                        if(input_query!=INPUT.val()){
                            input_query=INPUT.val();

                            if(input_query){
                                clearTimeout(timeout);

                                timeout=setTimeout(function(){
                                    APP.utils.suggest.show(input_query);
                                }, 200);
                            }

                            if(INPUT.val()==''){
                                APP.utils.suggest.clear();
                            }
                        }
                    }, 100);

                    setTimeout(function(){
                        INPUT.val('');

                        if(!INPUT.data('preselected')){
                            INPUT.attr('placeholder', INPUT.data('placeholder'));
                        }

                        /*INPUT.focus();*/
                    }, 500);

                    SEARCH_BUTTON.click(function(){
                        APP.utils.query();
                    });

                    BLOCKS.click(function(e){
                        if($(e.target).closest('.block').length){
                            return;
                        }

                        if(INPUT.val()!=''){
                            $('#suggest').show();
                        }

                        INPUT.focus();
                    });

                    INPUT.focus(function(){
                        if(INPUT.val()!=''){
                            $('#suggest').show();
                        }
                    });

                    $document.on('keydown', function(e) {
                        _self.isCmdDown = e[IS_MAC ? 'metaKey' : 'ctrlKey'];
                    });

                    $document.on('keyup', function(e) {
                        if (e.keyCode === KEY_CMD){
                            _self.isCmdDown = false;
                        }
                    });

                    INPUT.keydown(function(e){

                        switch (e.keyCode){
                            case 90://z
                                if(_self.isCmdDown && INPUT.val()==''){
                                    //undelete
                                    return false;
                                }
                                break;

                            case KEY_ESC:
                                APP.utils.suggest.clear();
                                return;
                                break;

                            case KEY_RETURN:
                                $('#suggest a:eq('+suggest_current+')').click();

                                return;
                                break;

                            case KEY_UP:
                                if(APP.utils.suggest.opened()){
                                    if(suggest_current>0){
                                        suggest_current--;

                                        $('#suggest a').removeClass('selected');
                                        $('#suggest a:eq('+suggest_current+')').addClass('selected');

                                        return;
                                    }
                                }
                                break;

                            case KEY_DOWN:
                                if(APP.utils.suggest.opened()){
                                    if(suggest_current<$('#suggest a').length-1){
                                        suggest_current++;

                                        $('#suggest a').removeClass('selected');
                                        $('#suggest a:eq('+suggest_current+')').addClass('selected');

                                        return;
                                    }
                                }
                                break;

                            case KEY_BACKSPACE:
                                if (INPUT.val()=='' && BLOCKS.find('.block').length){
                                    BLOCKS.find('.block:last').find('a.delete').click();

                                    return;
                                }
                                break;
                        }
                    });

                    if(INPUT.data('buttons') && INPUT.data('buttons')!='*'){
                        var buttons=INPUT.data('buttons').split(',');

                        for(var i=0;i<window.query_types.length;i++){

                            if(buttons.indexOf(window.query_types[i]().prefix)===-1){

                                delete window.query_types[i];
                            }
                        };
                    }

                    APP.utils.block.preload();
                    APP.utils.block.buttons();

                    $document.click(function(e){
                        if(APP.utils.suggest.opened() && !$(e.target).closest('#blocks').length){
                            $('#suggest').hide();
                        }
                    });

                    $document.on('click', '#blocks a.delete', function(e){
                        e.preventDefault();

                        var block=$(this).closest('.block'),
                            after_remove;

                        if(typeof block.data('block').remove === 'function'){
                            block.data('block').remove();
                        }

                        if(typeof block.data('block').after_remove === 'function'){
                            after_remove=block.data('block').after_remove;
                        }

                        block.remove();

                        if(after_remove){
                            after_remove();
                        }

                        APP.utils.callback.remove();

                        INPUT.focus();
                        APP.utils.query();
                    });

                    APP.utils.history.bind();
                    APP.utils.history.init();
                },

                sort: function (_self) {
                    _self.find('a').click(function (e) {
                        e.preventDefault();

                        var sort = $(this).data('sort');
                        SEARCH_QUERY.push('sort=value&order=' + sort);
                        APP.utils.history.push();
                        APP.utils.query();
                    });
                },

                platforms: function (_self) {
                    _self.find('a').click(function () {
                       var event = $(_self).data('ga-events');
                          if (event != '') {
                            var action = $(this).closest('[data-action]').data('action');

                            if(window.ga){
                                ga('send', {
                                    hitType: 'event',
                                    eventCategory: event,
                                    eventAction: action
                                });
                            }
                        }
                    });
                },

                platforms_table: function (_self) {
                    _self.find('a').click(function () {
                        var event = $(_self).data('ga-events');
                        if (event != '') {
                            var action = $(this).closest('[data-action]').data('action');
                            if(window.ga){
                                ga('send', {
                                    hitType: 'event',
                                    eventCategory: event,
                                    eventAction: action
                                });
                            }
                        }
                    });
                },

                tender_platforms: function (_self) {
                    _self.find('a').click(function () {
                        var platform = $(this).closest('[data-platform]').data('platform');
                        var status = $(this).closest('[data-platform]').data('tender-status');

                        if(window.ga){
                            ga('send', {
                                hitType: 'event',
                                eventCategory: platform,
                                eventAction: 'go_to_platform_tender',
                                eventLabel: status
                            });
                        }
                    });
                },

                dozorro_left_link: function (_self) {
                    var status = $(_self).data('tender-status');
                    _self.find('a').click(function (e) {
                        if(window.ga){
                            ga('send', {
                                hitType: 'event',
                                eventCategory: 'dozorro',
                                eventAction: 'header',
                                eventLabel: status
                            });
                        }
                    });
                },

                dozorro_header_link: function (_self) {
                    var status = $(_self).data('tender-status');
                    _self.find('a').click(function (e) {

                        if(window.ga){
                            ga('send', {
                                hitType: 'event',
                                eventCategory: 'dozorro',
                                eventAction: 'procurement-contact',
                                eventLabel: status
                            });
                        }
                    });
                },

                hint_list: function (_self) {
                    var hints=_self.find('.hint'),
                        i=0;

                    setInterval(function(){
                        if(i==hints.length){
                            i=0;
                        }

                        hints.hide();
                        hints.eq(i).show();

                        i++;
                    }, 5000);

                    $(_self).on('click', '.hint', function (e) {
                        var a = $(this).find('a'),
                            name = a.html();

                        if(window.ga){
                            ga('send', {
                                hitType: 'event',
                                eventCategory: 'Наприклад',
                                eventAction: 'Перехід',
                                eventLabel: name,
                                hitCallback: function(){
                                    window.location=a.attr('href');
                                }
                            });
                        }
                    });
                }
            },

            utils: {
                init_plan_print_button: function(){
                    if(SEARCH_TYPE != 'plan'){
                        return;
                    }
                    var show = 0,
                        SEARCH_QUERY = APP.utils.get_query(),
                        href = $('#print-list'),
                        header_totals = $('[header-totals]');

                    for(var i = 0; i < SEARCH_QUERY.length; i++){
                        if(SEARCH_QUERY[i].indexOf('dateplan[') >= 0)
                            show++;

                        if(SEARCH_QUERY[i].indexOf('edrpou=') >= 0)
                            show++;
                    }

                    href.hide();
                    $('#print-list-limit').hide();

                    if(header_totals.length) {
                        if(parseInt(header_totals.html().replace(' ', '')) > 2500) {
                            $('#print-list-limit').show();
                        } else if(parseInt(header_totals.html()) > 0 && SEARCH_QUERY.length == 2 && show == 2){
                            href.show();
                            href.attr('href', LANG + '/' + SEARCH_TYPE + '/search/print/html/?' + SEARCH_QUERY.join('&'));
                        }
                    }
                },
                totals: {
                    init: function(){
                        var items_list=$('.items-list');

                        $('[mobile-totals]').click(function(e){
                            e.preventDefault();

                            $((navigator.userAgent.match(/(iPod|iPhone|iPad|Android)/)?'body':'html,body')).animate({
                                scrollTop: items_list.position().top
                            }, 400);

                            return true;
                        });

                        APP.utils.totals.show();
                    },
                    show: function(){
                        var container=$('[mobile-totals]'),
                            header_totals=$('[header-totals]'),
                            total=header_totals.text();

                        if(total){
                            container.removeClass('none-important').find('.result-all-link span').text(total);
                        }else{
                            container.addClass('none-important');
                        }
                    },
                    reset: function(){
                        $('[mobile-totals]').addClass('none-important');
                    }
                },
                history: {
                    bind: function(){
                        if (IS_HISTORY){
                            window.History.Adapter.bind(window, 'statechange', function(){
                                var state = window.History.getState();
                            });
                        }
                    },
                    init: function(){
                        var search=location.search;

                        if(search && search.indexOf('=')>0){
                            search=search.substring(1).split('&');

                            for(var i=0;i<search.length;i++){
                                var param=search[i].split('=');
                                if(param[0] && param[1]){

                                    if(param[0].indexOf('date[')>=0){
                                        param[1]={
                                            type: param[0].match(/\[(.*?)\]/)[1],
                                            value: decodeURI(param[1]).split('—')
                                        };

                                        param[0]='date';
                                    }

                                    if(param[0].indexOf('dateplan[')>=0){
                                        param[1]={
                                            type: param[0].match(/\[(.*?)\]/)[1],
                                            value: decodeURI(param[1]).split('—')
                                        };

                                        param[0]='dateplan';
                                    }

                                    var button=$('<div/>');

                                    button.data('input_query', '');
                                    button.data('block_type', param[0]);
                                    button.data('preselected_value', param[1]);

                                    APP.utils.block.add(button);
                                }
                            }
                        }

                        APP.utils.result_highlight(INPUT.data('highlight'));
                        APP.utils.init_plan_print_button();

                        INITED=true;
                    },
                    push: function(){

                        if (IS_HISTORY){
                            var query = decodeHTMLEntities(LANG+'/'+SEARCH_TYPE+'/search/'+(SEARCH_QUERY.length ? '?'+SEARCH_QUERY.join('&') : ''));
                            window.History.pushState(null, document.title, query);
                        }
                    }
                },
                get_query: function(){
                    var out = [];

                    $('.block').each(function(){
                        var self = $(this),
                            block = self.data('block'),
                            type = block.prefix;

                        if(typeof block.result === 'function'){
                            var result = block.result();

                            if(typeof result === 'object'){
                                out.push(result.join('&'));
                            }else if(result){
                                out.push(type + '=' + result);
                            }
                        }
                    });
                    console.log(out);
                    return out;
                },
                query: function(){
                    if(!INITED){
                        return false;
                    }

                    clearTimeout(SEARCH_QUERY_TIMEOUT);

                    SEARCH_QUERY_TIMEOUT=setTimeout(function(){
                        SEARCH_QUERY=APP.utils.get_query();

                        $('#server_query').val(SEARCH_QUERY.join('&'));
                        SEARCH_BUTTON.prop('disabled', SEARCH_QUERY.length?'':'disabled')

                        APP.utils.history.push();

                        if(!SEARCH_QUERY.length){
                            $('#result').html('');

                            return;
                        }

                        $('#search_button').addClass('loading').spin(spin_options);

                        SEARCH_QUERY.forEach(function (e){
                            var param = e.split('=');
                            if (param[0]==='region'){
                                var region_name='';
                                    $.each(REGION[0], function(key, value){
                                        if (key===param[1]){
                                            region_name=value;
                                            return false;
                                        }
                                    });

                                    if(window.ga){
                                        ga('send', {
                                            hitType: 'event',
                                            eventCategory: 'filter-query',
                                            eventAction: param[0],
                                            eventLabel: region_name
                                        });
                                    }
                            } else {
                                if(window.ga){
                                    ga('send', {
                                        hitType: 'event',
                                        eventCategory: 'filter-query',
                                        eventAction: param[0],
                                        eventLabel: param[1]
                                    });
                                }
                            }
                        });

                        $.ajax({
                            url: LANG+'/'+SEARCH_TYPE+'/form/search',
                            data: {
                                query: SEARCH_QUERY
                            },
                            method: 'post',
                            headers: APP.utils.csrf(),
                            dataType: "json",
                            success: function(response){

                                $('#search_button').removeClass('loading').spin(false);

                                if(INPUT.data('homepage')){
                                    $('main>*:not(.block_form_tabs)').filter('div').remove();
                                }

                                if(response.html){
                                    $('#result').html(response.html);

                                    APP.utils.totals.show();
                                    APP.utils.result_highlight(response.highlight);

                                    APP.utils.init_plan_print_button();

                                    var total_result = '';
                                    if (response.total > 0 && response.total <= 10) {
                                        total_result = '<10';
                                    } else if (response.total > 10 && response.total <= 100) {
                                        total_result = '<100';
                                    } else if (response.total > 100 && response.total <= 1000) {
                                        total_result = '<1K';
                                    } else if (response.total > 1000 && response.total <= 10000) {
                                        total_result = '<10K';
                                    } else if (response.total > 10000 && response.total <= 100000) {
                                        total_result = '<100K';
                                    } else if (response.total > 100000 && response.total <= 1000000) {
                                        total_result = '<1M';
                                    } else if (response.total > 1000000) {
                                        total_result = '1M+';
                                    }

                                    if (response.total != 0) {
                                        if(window.ga){
                                            ga('send', {
                                                hitType: 'event',
                                                eventCategory: 'filter-result-' + response.search_type,
                                                eventAction: 'success',
                                                eventLabel: total_result
                                            });
                                        }
                                    } else {
                                        if(window.ga){
                                            ga('send', {
                                                hitType: 'event',
                                                eventCategory: 'filter-result-' + response.search_type,
                                                eventAction: 'no-results'
                                            });
                                        }
                                    }

                                }else{
                                    $('#result').html(INPUT.data('no-results'));

                                    if(window.ga){
                                        ga('send', {
                                            hitType: 'event',
                                            eventCategory: 'filter-result-' + response.search_type,
                                            eventAction: 'no-results'
                                        });
                                    }
                                }
                            }
                        });
                    }, 300);
                },
                result_highlight: function(words){
                    if(words){
                        $.each(words, function(key, value){
                            $('#result').highlight(value, {
                                element: 'i',
                                className: 'select'
                            });
                        });
                    }
                },
                block: {
                    remove: function(e){
                        e.preventDefault();

                    },
                    create: function(block_type){
                        for(var i=0; i<window.query_types.length; i++){
                            if(typeof window.query_types[i] === 'function'){
                                var type=window.query_types[i]();

                                if(type.prefix==block_type){
                                    return type;
                                }
                            }
                        }
                    },
                    add: function(self){
                        var input_query=self.data('input_query'),
                            block_type=self.data('block_type'),
                            block=APP.utils.block.create(block_type),
                            template=block && block.template ? block.template.clone().html() : null,
                            is_exact=false//(type.pattern_exact && type.pattern_exact.test(input))

                        if(!template){
                            return;
                        }

                        block.value=input_query;
                        template=APP.utils.parse_template(template, block);

                        INPUT.removeClass('no_blocks').removeAttr('placeholder');

                        if(self.data('preselected_value')){
                            template.data('preselected_value', self.data('preselected_value'));
                        }

                        if(self.data('multi_value')){
                            template.data('multi_value', self.data('multi_value'));
                        }

                        template.append('<a href="" class="delete">×</a>');

                        BLOCKS.append(template);
                        BLOCKS.append(INPUT);

                        if(typeof block.init === 'function'){
                            block=block.init(input_query, template);
                        }else{
                            INPUT.focus();
                        }

                        if(typeof block.after_add === 'function'){
                            block.after_add();
                        }

                        template.data('block', block);

                        INPUT.val('');
                    },
                    preload: function(){
                        for(var i=0; i<window.query_types.length; i++){
                            if(typeof window.query_types[i] === 'function'){
                                var type=window.query_types[i]();

                                if(typeof type.load === 'function'){
                                    type.load();
                                }
                            }
                        }
                    },
                    buttons: function(){
                        var button_blocks=[];

                        for(var i=0; i<window.query_types.length; i++){
                            if(typeof window.query_types[i] === 'function'){
                                var type=window.query_types[i]();

                                if(type.button_name || type.template.data('buttonName')){
                                    button_blocks.push(type);
                                }
                            };
                        }

                        button_blocks.sort(function(a, b){
                            if (a.order < b.order)
                                return -1;

                            if (a.order > b.order)
                                return 1;

                            return 0;
                        });

                        for(var i=0; i<button_blocks.length; i++){
                            APP.utils.button.add(button_blocks[i]);
                        }
                    }
                },
                callback: {
                    remove: function(){
                        if(!BLOCKS.find('.block').length){
                            INPUT.addClass('no_blocks');
                            INPUT.attr('placeholder', INPUT.data('placeholder'));
                            APP.utils.totals.reset();
                        }
                    },
                    check: function(suggest){
                        return function(response, textStatus, jqXHR){
                            if(response){
                                suggest.removeClass('none');
                            }else{
                                suggest.remove();
                            }
                        }
                    }
                },
                button: {
                    add: function(block){
                        var button=$('#helper-button').clone().html(),
                            button_data_name=block.template.data('buttonName');

                        button=$(button.replace(/\{name\}/, button_data_name ? button_data_name : block.button_name));

                        button.data('input_query', '');
                        button.data('block_type', block.prefix);

                        button.click(function(e){
                            e.preventDefault();

                            APP.utils.block.add($(this));
                        });

                        $('#buttons').append(button);
                    }
                },
                suggest: {
                    show: function(input_query){

                        input_query = decodeHTMLEntities(input_query);

                        var blocks=APP.utils.detect_query_block(input_query),
                            row,
                            item,
                            suggestName;

                        APP.utils.suggest.clear();

                        if(blocks.length){
                            $.each(blocks, function(index, block){
                                row=$('#helper-suggest').clone().html();

                                if(typeof block.suggest_item=='function'){
                                    row=block.suggest_item(row, input_query);
                                }else{
                                    suggestName=block.template.data('suggestName');

                                    row=row.replace(/\{name\}/, suggestName ? suggestName : block.name);
                                    row=row.replace(/\{value\}/, input_query);
                                }

                                if(row){
                                    item=$(row);

                                    if(input_query && block.json && block.json.check){
                                        $.ajax({
                                            url: LANG+'/'+SEARCH_TYPE+block.json.check,
                                            method: 'POST',
                                            dataType: 'json',
                                            headers: APP.utils.csrf(),
                                            data: {
                                                query: input_query
                                            },
                                            success: APP.utils.callback.check(item)
                                        });
                                    }else{
                                        item.removeClass('none');
                                    }

                                    item.data('input_query', input_query);
                                    item.data('block_type', block.prefix);

                                    item.click(function(e){
                                        e.preventDefault();

                                        APP.utils.block.add($(this));
                                    });

                                    $('#suggest').append(item);
                                }
                            });

                            $('#suggest a:first').addClass('selected');

                            $('#suggest').show();

                            suggest_opened=true;
                        }
                    },
                    clear: function(){
                        $('#suggest').hide().empty();
                        suggest_current=0;
                        suggest_opened=false;
                    },
                    opened: function(){
                        return suggest_opened;
                    }
                },
                detect_query_block: function(query){
                    var types=[];

                    for(var i=0; i<window.query_types.length; i++){
                        if(typeof window.query_types[i] === 'function'){
                            var type=window.query_types[i]();

                            if(typeof type.validate === 'function' && type.validate(query)){
                                types.push(type);
                            } else if(type.pattern_search.test(query)){
                                types.push(type);
                            }
                        }
                    }

                    types.sort(function(a, b){
                        if (a.order < b.order)
                            return -1;

                        if (a.order > b.order)
                            return 1;

                        return 0;
                    });

                    return types;
                },
                parse_template: function(template, data){
                    for(var i in data){
                        template=template.replace(new RegExp('{' + i + '}', 'g'), data[i]);
                    }

                    return $(template);
                },
                csrf: function(){
                    return {
                        'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
                    };
                }
            }
        };

        return methods;
    }());

    APP.common();

    $(function (){
        $('[data-js]').each(function(){
            var self = $(this);

            if (typeof APP.js[self.data('js')] === 'function'){
                APP.js[self.data('js')](self, self.data());
            } else {
                console.log('No `' + self.data('js') + '` function in app.js');
            }
        });
    });

})(window);

String.prototype.trunc = String.prototype.trunc || function(n){
    return (this.length > n) ? this.substr(0, n-1)+'&hellip;' : this;
};

function eventMapInit(){
    var _self=$('#map'),
        uluru=_self.data('gps');

    var map = new google.maps.Map(document.getElementById('map'), {
        zoom: 16,
        center: uluru
    });

    var marker = new google.maps.Marker({
        position: uluru,
        map: map
    });
}

function setArrowsPosition(slider) {
    var sliderDotsWidth, sliderArrowMargin;
    sliderDotsWidth = slider.data('sliderDots').width();
    sliderArrowMargin = sliderDotsWidth / 2 + 20;
    slider.data('sliderArrowPrev').css('margin-right', sliderArrowMargin);
    slider.data('sliderArrowNext').css('margin-left', sliderArrowMargin);

};

$('.jsBrandsSlider').on('init', function(event, slick) {
    $(this).data('sliderDots', $(this).find('.slick-dots'));
    $(this).data('sliderArrowPrev', $(this).find('.slick-prev'));
    $(this).data('sliderArrowNext', $(this).find('.slick-next'));
    setArrowsPosition($(this));
});
$('.jsBrandsSlider').on('afterChange', function(event, slick) {
    $(this).data('sliderDots', $(this).find('.slick-dots'));
    $(this).data('sliderArrowPrev', $(this).find('.slick-prev'));
    $(this).data('sliderArrowNext', $(this).find('.slick-next'));
    setArrowsPosition($(this));
});

$('.jsBrandsSlider').slick({
    dots: true,
    arrows: true,
    slidesToShow: 5,
    slidesToScroll: 5,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 5000,
    pauseOnFocus: false,
    responsive: [
        {
            breakpoint: 1199,
            settings: {
                slidesToShow: 4,
                slidesToScroll: 4
            }
        },
        {
            breakpoint: 959,
            settings: {
                slidesToShow: 3,
                slidesToScroll: 3
            }
        },
        {
            breakpoint: 670,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 2
            }
        },
        {
            breakpoint: 450,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1
            }
        }
    ]
});
