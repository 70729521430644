const selectCustom = (function() {
    const classMain = 'js-select-custom';
    const classDropdown = 'js-select-custom-dropdown';
    const classItem = 'js-select-custom-item';
    const classCheckbox = 'js-select-custom-checkbox';
    const classStatus = 'js-select-custom-status';
    const classActive = 'active';

    const innerClasses = [
        classMain,
        classDropdown,
        classItem,
    ];

    const elemStatus = document.querySelector(`.${classStatus}`);
    const elemsMain = document.querySelectorAll(`.${classMain}`);
    const elemsCheckbox = document.querySelectorAll(`.${classCheckbox}`);

    const state = {
        defaultText: '',
        countSelectedCheckbox: 0,
    };

    const cacheDefaultText = () => {
        state.defaultText = elemStatus.innerHTML;
    };

    /**
     * @param {string} counterText
     */
    const updateStatus = counterText => {
        if (state.countSelectedCheckbox === 0) {
            elemStatus.innerHTML = state.defaultText;
            return;
        }

        elemStatus.innerHTML = `${counterText} ${state.countSelectedCheckbox}`;
    };

    /**
     * @param {string} counterText
     * @param {string[]=} additionalInnerClasses
     */
    const init = (counterText, additionalInnerClasses = []) => {
        const classesToSkip = [...innerClasses, ...additionalInnerClasses];

        cacheDefaultText();

        document.addEventListener('click', event => {
            if (classesToSkip.some(className => [].includes.call(event.target.classList, className))) {
                return;
            }

            elemsMain.forEach(elem => {
                elem.classList.remove(classActive);
            });
        });

        elemsMain.forEach(elem => {
            elem.addEventListener('click', event => {
                if (event.target !== event.currentTarget) {
                    return;
                }

                elem.classList.toggle(classActive);
            });
        });

        elemsCheckbox.forEach(elem => elem.addEventListener('change', event => {
            /** @type {HTMLInputElement} */ const {target} = event;
            state.countSelectedCheckbox += target.checked ? 1 : -1;
            updateStatus(counterText);
        }));
    };

    return {
        init,
    };
})();
