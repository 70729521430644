/*!
 *
 * Prozorro v1.0.0
 *
 * Author: Lanko Andrey (lanko@perevorot.com)
 *
 * © 2015
 *
 */

var APPTABS,
    spin_options={
        color:'#6dc8eb',
        lines: 15,
        width: 2
    };

(function(window, undefined){

    'use strict';

    APPTABS = (function(){

        return {
            common: function(){
                
            },

            js: {
                init: function(_self){
                    var tabs=$('[tab-href]'),
                        contents=$('[tab-content]'),
                        menus=$('[tabs-menu] a'),
                        tenders=$('[tenders]'),
                        more=$('[tabs-more]'),
                        pagesTotal,
                        pagesStart=0,
                        tabIndex,
                        _filter,
                        loader=$('[tab-loader]'),
                        inited=false;

                    var loadData=function(f){
                        loader.spin(spin_options);

                        $.ajax({
                            url: _self.data('api'),
                            data: {
                                query: _filter
                            },
                            method: 'post',
                            headers: APP.utils.csrf(),
                            dataType: "json",
                            success: function(response){
                                loader.spin(false);

                                if(response.html){
                                    tenders.eq(tabIndex).html(response.html);
                                    pagesTotal=response.total;

                                    if(pagesTotal>10){
                                        more.show();
                                    }
                                }else{
                                    tenders.eq(tabIndex).html(response);
                                }
                            }
                        });
                    };
                    
                    more.hide();

                    more.click(function(e){
                        e.preventDefault();

                        pagesStart+=10;
                        loader.spin(spin_options);

                        $.ajax({
                            url: _self.data('api'),
                            data: {
                                query: _filter,
                                start: pagesStart
                            },
                            method: 'post',
                            headers: APP.utils.csrf(),
                            dataType: "json",
                            success: function(response){
                                loader.spin(false);

                                if(response.html){
                                    tenders.eq(tabIndex).append(response.html);
                                    pagesTotal=response.total;

                                    if(pagesStart+10 > pagesTotal){
                                        more.hide();
                                    }
                                }
                            }
                        });
                    });

                    tabs.click(function(e){
                        e.preventDefault();
                        
                        var self=$(this);
                        
                        tabIndex=self.index('[tab-href]');

                        tabs.removeClass('active');
                        self.addClass('active');

                        contents.hide();
                        contents.eq(tabIndex).show();

                        _filter=self.data('filter') ? self.data('filter').split('&') : [];

                        more.hide();

                        if(inited){
                            loadData();
                        }
                    });
                    
                    menus.click(function(e){
                        e.preventDefault();
                       
                        var self=$(this);

                        more.hide();
                        pagesStart=0;

                        _filter=self.data('filter');

                        menus.removeClass('active');
                        self.addClass('active');
                        
                        _filter=self.data('filter') ? self.data('filter').split('&') : [];

                        loadData();
                    });

                    if(tabs.length>1){
                        inited=true;
                        tabs.eq(0).click();
                    }else{
                        inited=false;
                        tabs.eq(0).click();
                        menus.eq(0).click();
                        inited=true;
                    }
                },
                csrf: function(){
                    return {
                        'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
                    };
                }
            }
        };
    }());

    APPTABS.common();

    $(function (){
        $('[data-tabsjs]').each(function(){
            var self = $(this);

            if (typeof APPTABS.js[self.data('tabsjs')] === 'function'){
                APPTABS.js[self.data('tabsjs')](self, self.data());
            } else {
                console.log('No `' + self.data('tabsjs') + '` function in app.js');
            }
        });
    });

})(window);